import React from 'react'
import "./Title.scss"
import { Link } from 'react-router-dom'

const Title = ({ title, titleurl, textWhite }) => {
  return (
    <Link to={titleurl ? `${titleurl}` : ''}>
      <h2 className='underlined-title animated animatedFadeInUp fadeInUp' style={textWhite && { color: '#fff' }} >{title}</h2>
    </Link>

  )
}

export default Title