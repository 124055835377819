import i18next from 'i18next';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'


function MetaHead({ title, keyword, description }) {
    // LANGUAGE
    const lang = localStorage.getItem('localLang')

    return (
        <Helmet>
            <title> {`Emirates Human Right Association | ${title}`}</title>
            <meta name="keywords" content={keyword} />
            <meta name="description" content={`${description}`} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={`${description}`} />
            <meta property="og:site_name" content="Emirates Human Right Association" />
            <meta property="og:locale" content={`${lang}`} />
            <meta property="og:image" content={'https://admin.hruae.org/home/1703576315032_8888[1].png'} />
            <meta property="og:image:secure" content='https://admin.hruae.org/home/1703576315032_8888[1].png'/>
        </Helmet>

    )
}

export default MetaHead
