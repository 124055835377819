import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import TitleBar from '../components/TitleBar/TitleBar'
import { useDispatch, useSelector } from 'react-redux';
import i18n from "../i18n";
import { getTerms } from '../redux/actionCreator';
import { termsDetails } from '../redux/commonReducer';
import { t } from 'i18next';
import DummyImage from '../components/DummyImage/DummyImage';

function Terms() {
  const dispatch = useDispatch();
  // SELECTOR
  const terms_Details = useSelector(termsDetails);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getTerms())
  }, []);
  return (
    <section className='terms__conditions'>
      <Container>
      <TitleBar pt0 noBtn />
        {
          (terms_Details?.title == '' && terms_Details?.description == '') ?
            <DummyImage size={'60%'} /> :
         
           
           <>
            <h2>{langSwitch ? terms_Details?.title : terms_Details?.title_ar}</h2>
            <div className='content' dangerouslySetInnerHTML={{ __html: langSwitch ? terms_Details?.description : terms_Details?.description_ar }}></div>
           </>

        }
      </Container>
    </section>
  )
}

export default Terms
