import React, { useEffect, useState } from "react";
import "./pagesStyles.scss";
import { Container } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import RoundedButton from "../commonComponents/RoundedButton/RoundedButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  featuredNews,
  newsLists,
  newsRemaining_count,
} from "../redux/commonReducer";
import { getFeaturedNews, getNews } from "../redux/actionCreator";
import { API_IMAGE_URL } from "../constants/configuration";
import moment from "moment";
import i18n from "../i18n";
import { t } from "i18next";
function News() {
  const dispatch = useDispatch();

  const [newsItems, setnewsItems] = useState([]);
  const [page, setPage] = useState(0);

  // SELECTOR
  const listedNews = useSelector(newsLists);
  const Remaining_count = useSelector(newsRemaining_count);
  const featured = useSelector(featuredNews);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", page);
    formData.append("perPage", 8);

    dispatch(
      getNews(formData, (res) => {
        setnewsItems([...newsItems, ...res.data]);
      })
    );
  }, [page]);

  useEffect(() => {
    dispatch(getFeaturedNews());
  }, []);

  const handleViewMore = () => {
    setPage(page + 1);
  };


  return (
    <section className="news">
      <Container>
        <TitleBar
          title={t('news')}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('news'), path: "/News" },
          ]}
          noBtn
        />

        <div className="featured_news">
          <div className="row">
            {featured?.map((ele, i) => {
              return (
                <div className="col-md-6 scale" key={i}>
                  <Link to={`/News/news-details/${ele?._id}`}>
                    <div className="news__grid">
                      <div className="img__sec">
                        <img
                          src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                          className="w-100"
                          alt=""
                        />
                      </div>
                      <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                      <h6>{moment.utc(ele?.created_date).locale(lang).format("LL")}</h6>
                      <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length > 400 ?  ele?.content?.slice(0,400) + '(...)' :ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div className="news__items">
          <div className="row">
            {newsItems?.map((ele, i) => {
              return (
                <div className="col-md-3 scale" key={i}>
                  <Link to={`/News/news-details/${ele?._id}`}>
                    <div className="news__grid">
                      <div className="img__sec">
                        <img
                          src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                          className="w-100"
                          alt=""
                        />
                      </div>
                      <h6>{moment.utc(ele?.created_date).locale(lang).format("LL")}</h6>
                      <h2>{langSwitch ? ele?.title : ele?.title_ar} </h2>
                      <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length > 100 ?  ele?.content?.slice(0,100) + '(...)' :ele?.content) : (ele?.content_ar?.length > 100 ? ele?.content_ar?.slice(0,100)+'(...)' : ele?.content_ar)}}></div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        {!Remaining_count == 0 && (
          <div className="text-center mb-5 mt-4">
            <RoundedButton
              varient="secondary"
              style={{ minWidth: "190px" }}
              onClick={() => handleViewMore()}
              title="View More"
            />
          </div>
        )}
      </Container>
    </section>
  );
}

export default News;
