import React, { useEffect, useRef, useState } from "react";
import SlidesComponent from "../../commonComponents/SlidesComponent/SlidesComponent";
import "./LatestNewsSection.scss";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedNews, getNews } from "../../redux/actionCreator";
import { featuredNews, newsLists } from "../../redux/commonReducer";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { API_IMAGE_URL } from "../../constants/configuration";
import moment from "moment";
import i18n from "../../i18n";
import { t } from "i18next";

const LatestNewsSection = () => {
  const dispatch = useDispatch()

  // SELECTOR
  const listedNews = useSelector(newsLists)
  const featured = useSelector(featuredNews)

  const allNews = featured?.concat(listedNews)

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getNews())
    dispatch(getFeaturedNews())
  }, [])
  let newsData = (index, item) => (
    <SwiperSlide>
      <Link to={`/News/news-details/${item?._id}`}>
        <div className="news-grid" key={index}>
          <Row>
            <div className="section-img">
              {
                item?.image ? <img src={`${API_IMAGE_URL}${item?.file_path}${item?.image}`} width={"100%"} /> :
                  <img src={'./assets/img/dummy.png'} width={"100%"} />
              }

            </div>
          </Row>
          <Row className="p-1">
            <h6 className="mt-2">{moment.utc(item?.created_date).format("LL")}</h6>
            <h2>{langSwitch ? item?.title : item?.title_ar}</h2>
            <div className='content' dangerouslySetInnerHTML={{ __html: langSwitch ? (item?.content?.length > 100 ? item?.content?.slice(0, 100) + '(...)' : item?.content) : (item?.content_ar?.length > 100 ? item?.content_ar?.slice(0, 100) + '(...)' : item?.content_ar) }}></div>
          </Row>
        </div>
      </Link>
    </SwiperSlide>
  );

  return (
    <section className="humanRights__news">
      {
        newsData?.length > 0 ?
          <SlidesComponent
            title={t("latest news")}
            titleurl={'/News'}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
            }}
            dataArray={allNews}
            tile={newsData}
          /> : ''
      }

    </section>
  );
};

export default LatestNewsSection;
