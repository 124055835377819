import React, { useEffect, useState } from "react";
import "./pagesStyles.scss";
import TitleBar from "../components/TitleBar/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { getAboutus, getBoardmembers } from "../redux/actionCreator";
import { about_details, board_members } from "../redux/commonReducer";
import { API_IMAGE_URL } from "../constants/configuration";
import i18n from "../i18n";
import { t } from "i18next";
import Title from "../commonComponents/Title/Title";

function About() {
  const dispatch = useDispatch()

  // SELECTOR
  const aboutData = useSelector(about_details)
  const teamData = useSelector(board_members);
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getAboutus())
    dispatch(getBoardmembers());
  }, [])

  return (
    <section className="humanRights__about">
      <div className="container">
        <TitleBar
          title={t('about us')}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('about us'), path: "/about" },
          ]}
          btnUrl={'/board-members'}
          label={t('view board members')}
          varient={'secondary'}
          noBtn={teamData?.length == 0 ? true : false}
        />

        <div className="about__featured">
          <div className="row">
            <div className="col-md-12">
              <div className="content" data-aos="fade-up" data-aos-duration="1500">
                <Title textWhite title={langSwitch ? aboutData?.main_title : aboutData?.main_title_ar} />
                {/* <h2></h2> */}
              </div>
            </div>
            <div className="col-md-12">
              <div className="content mt-3" data-aos="fade-up" data-aos-duration="2000">
                <p dangerouslySetInnerHTML={{ __html: langSwitch ? aboutData?.description : aboutData?.description_ar }}></p>
              </div>
            </div>
          </div>
        </div>
        <div className="vison__grids">
          <div className="row">
            {

              aboutData?.vision_icon && aboutData?.vision_title && aboutData?.vision_description &&
              <div className="col-md-12 grid__items" data-aos="fade-up" data-aos-duration="1500" >
                <div className="header">
                  <img src={`${API_IMAGE_URL}${aboutData?.file_path}${aboutData?.vision_icon}`} alt="" />
                  <h3>{langSwitch ? aboutData?.vision_title : aboutData?.vision_title_ar}</h3>
                </div>
                <div className="description">
                  <p dangerouslySetInnerHTML={{ __html: langSwitch ? aboutData?.vision_description : aboutData?.vision_description_ar }}></p>
                </div>
              </div>
            }
            {
              aboutData?.mission_icon && aboutData?.mission_title && aboutData?.mission_description &&
              <div className="col-md-12 grid__items" data-aos="fade-up" data-aos-duration="2000">
                <div className="header">
                  <img src={`${API_IMAGE_URL}${aboutData?.file_path}${aboutData?.mission_icon}`} alt="" />
                  <h3>{langSwitch ? aboutData?.mission_title : aboutData?.mission_title_ar}</h3>
                </div>
                <div className="description">
                  <p dangerouslySetInnerHTML={{ __html: langSwitch ? aboutData?.mission_description : aboutData?.mission_description_ar }}></p>
                </div>
              </div>
            }

            {
              aboutData?.objectives_title && aboutData?.objectives_icon && aboutData?.objectives_description &&
              <div className="col-md-12 grid__items" data-aos="fade-up" data-aos-duration="2500">
                <div className="header">
                  <img src={`${API_IMAGE_URL}${aboutData?.file_path}${aboutData?.objectives_icon}`} alt="" />
                  <h3>{langSwitch ? aboutData?.objectives_title : aboutData?.objectives_title_ar}</h3>
                </div>
                <div className="description">
                  <p dangerouslySetInnerHTML={{ __html: langSwitch ? aboutData?.objectives_description : aboutData?.objectives_description_ar }}></p>
                </div>
              </div>
            }

            {
              aboutData?.strategy_icon && aboutData?.strategy_title && aboutData?.strategy_description &&
              <div className="col-md-12 grid__items" data-aos="fade-up" data-aos-duration="3000">
                <div className="header">
                  <img src={`${API_IMAGE_URL}${aboutData?.file_path}${aboutData?.strategy_icon}`} alt="" />
                  <h3>{langSwitch ? aboutData?.strategy_title : aboutData?.strategy_title_ar}</h3>
                </div>
                <div className="description">
                  <p dangerouslySetInnerHTML={{ __html: langSwitch ? aboutData?.strategy_description : aboutData?.strategy_description_ar }}></p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
