import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TitleBar from "../TitleBar/TitleBar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import { getAwarenessDetails } from "../../redux/actionCreator";
import { awarenessDetails } from "../../redux/commonReducer";
import { API_IMAGE_URL } from "../../constants/configuration";
import { t } from "i18next";

function AwarenessDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  // SELECTORS
  const awareness_Detail = useSelector(awarenessDetails);

  useEffect(() => {
    dispatch(getAwarenessDetails(id));
  }, [id]);

  let pdffile = langSwitch ? awareness_Detail?.document : awareness_Detail?.document_ar


  return (
    <div className="report__details">
      <Container>
        <div className="Titlebar_btn">
          <TitleBar
            title={
              langSwitch ? awareness_Detail?.title : awareness_Detail?.title_ar
            }
            breadcrumbs={[
              { name: t('home'), path: "/" },
              { name: t('library'), path: "/Library" },
              { name: t('awareness'), path: "/library/awareness" },
            ]}
            capitalize
            label="View PDF"
            varient={"warning"}
            noBtn
          />

          {awareness_Detail?.document && (
            <a
              href={`${API_IMAGE_URL}${awareness_Detail?.file_path}${pdffile}`}
              target="_blank"
            >
              <button className="btn btn-warning text-center" style={{ minWidth: "140px" }}>
              {t('View PDF')}
              </button>
            </a>
          )}
        </div>

        <img
          src={`${API_IMAGE_URL}${awareness_Detail?.file_path}${awareness_Detail?.thumbnail}`}
          alt=""
        />
        <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? awareness_Detail?.content : awareness_Detail?.content_ar}}></div>
      </Container>
    </div>
  );
}

export default AwarenessDetails;
