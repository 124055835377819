import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import TitleBar from "../components/TitleBar/TitleBar";
import i18n from "../i18n";
import RoundedButton from "../commonComponents/RoundedButton/RoundedButton";
import { useDispatch, useSelector } from "react-redux";
import { researchesItems, researches_count } from "../redux/commonReducer";
import { getResearch } from "../redux/actionCreator";
import { API_IMAGE_URL } from "../constants/configuration";
import { t } from "i18next";
import DummyImage from "../components/DummyImage/DummyImage";

function Research() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [researchItems, setresearchItems] = useState([]);

  // SELECTOR
  const eventItems = useSelector(researchesItems);
  const Remaining_count = useSelector(researches_count);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", page);
    formData.append("perPage", 8);
    dispatch(
      getResearch(formData, (res) => {
        setresearchItems([...researchItems, ...res.data]);
      })
    );
  }, [page]);

  const handleViewMore = () => {
    setPage(page + 1);
  };
  return (
    <Container>
      <TitleBar
        title={t('research')}
        breadcrumbs={[
          { name: t('home'), path: "/" },
          { name: t('research'), path: "/Research" },
        ]}
        noBtn
      />

      {
        researchItems?.length == 0 ?
        <DummyImage size={'60%'} /> :

        <div className="row mb-5">
        {researchItems?.map((ele, i) => {
          return (
            <div className="col-md-3" key={i}>
              <Link to={`/Research/research-details/${ele?._id}`}>
                <div className="library__reports">
                  <div className="library__image">
                    <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}`} alt="" />
                  </div>
                  <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                  <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length > 100 ?  ele?.content?.slice(0,100) + '(...)' :ele?.content) : (ele?.content_ar?.length > 100 ? ele?.content_ar?.slice(0,100)+'(...)' : ele?.content_ar)}}></div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      }

      {!Remaining_count == 0 && (
        <div className="text-center mb-5 mt-4">
          <RoundedButton
            varient="secondary"
            style={{ minWidth: "190px" }}
            onClick={() => handleViewMore()}
            title="View More"
          />
        </div>
      )}
    </Container>
  );
}

export default Research;
