import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL, API_INSTA_TOKEN, SYNC_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';
import { updateRedux } from './commonReducer';



export const getAllHomeData = () => (dispatch) => {
  dispatch(updateRedux({ key: "home_loader", value: true }))
  axiosInstance.get(`${API_BASE_URL}get-home-data`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "home_loader", value: false }))
      dispatch(updateRedux({ key: "getHome_banner", value: res.data.data.banners }))
      dispatch(updateRedux({ key: "getHome_announcements", value: res.data.data.announcements }))
      dispatch(updateRedux({ key: "getHome_details", value: res.data.data.details }))
    } else {
      dispatch(updateRedux({ key: "home_loader", value: true }))
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getGeneralData = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}get-general-data`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "generalData", value: res.data.data }))
      dispatch(updateRedux({ key: "contact_details", value: res.data.contact_details }))
      dispatch(updateRedux({ key: "logo_ar", value: res.data.logoArabicLink }))
      dispatch(updateRedux({ key: "logo_en", value: res.data.logoLink }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getBoardmembers = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}get-team`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "board_members", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}
export const getBoardmemberDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}get-team/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "memberDetails", value: res.data.team_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getNews = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-news`,formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "newsLists", value: res.data.data }))
      dispatch(updateRedux({ key: "newsRemaining_count", value: res.data.remaining_count }))
      if(callback){
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}
export const getFeaturedNews = () => (dispatch) => {
  axiosFormInstance.get(`${API_BASE_URL}get-featured-news`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "featuredNews", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getNewsDetails = (id) => (dispatch) => {
  axiosFormInstance.get(`${API_BASE_URL}get-news/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "newsDetail", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAboutus = () => (dispatch) => {
  axiosFormInstance.get(`${API_BASE_URL}aboutus`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "about_details", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getEvents = (formData, type, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-events`,formData).then((res) => {
    if (res.data.status) {
       if(type === 'local') {
        dispatch(updateRedux({ key: "eventLists", value: res.data.data} ))
        dispatch(updateRedux({ key: "eventsLocal_count", value: res.data.remaining_count }))
        callback(res.data)
       }else if(type === 'international'){
        dispatch(updateRedux({ key: "eventListsInternational", value: res.data.data} ))
        dispatch(updateRedux({ key: "eventsInternational_count", value: res.data.remaining_count }))
        callback(res.data)
       }

    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getFeaturedEvents = (formData) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-featured-events`,formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "featuredEventLists", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getEventDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}get-events/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "eventDetails", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getWhatWeDo = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}whatwedo`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "whatwedo_list", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getGallery = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-gallery`,formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "galleryItems", value: res.data.data }))
      dispatch(updateRedux({ key: "gallery_count", value: res.data.remaining_count }))
      callback(res.data.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getGalleryImages = (id,callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}getGalleryById/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "galleryImages", value: res.data.data }))
      callback(res.data.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getVideos = (formData,callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-videos`,formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "videosItems", value: res.data.data }))
      dispatch(updateRedux({ key: "videos_count", value: res.data.remaining_count }))
      callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getFeaturedVideos = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}get-featured-videos`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "featuredVideosItems", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getReports = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-reports`,formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "reportItems", value: res.data.data }))
      dispatch(updateRedux({ key: "report_count", value: res.data.remaining_count }))
      callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getLibraryDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}get-library/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "reportDetails", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAwarenessDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}get-library/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "awarenessDetails", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}



export const getAwereness = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-awereness`,formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "awerenessItems", value: res.data.data }))
      dispatch(updateRedux({ key: "awereness_count", value: res.data.remaining_count }))
      callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getResearch = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-researches`,formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "researchesItems", value: res.data.data }))
      dispatch(updateRedux({ key: "researches_count", value: res.data.remaining_count }))
      callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getResearchDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}get-researches/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "researchDetails", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getFAQ = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-faq`,formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "FAQItems", value: res.data.data }))
      // dispatch(updateRedux({ key: "FAQ_count", value: res.data.remaining_count }))
      callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const contactEnquiry = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}enquiry`,data).then((res) => {
    if (res.data.status) {
      callback(res)
    } else {
      callback(res)
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getTerms = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}terms-condition`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "termsDetails", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}
export const getPrivacy = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}privacy-policy`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "privacyDetails", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getSearchResults = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "search_loader", value: true }))
  axiosInstance.post(`${API_BASE_URL}all-search`,data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "search_teams", value: res.data.data.teamList }))
      dispatch(updateRedux({ key: "search_faq", value: res.data.data.faqList }))
      dispatch(updateRedux({ key: "search_localEvents", value: res.data.data.localEventList }))
      dispatch(updateRedux({ key: "search_interEvents", value: res.data.data.intEventList }))
      dispatch(updateRedux({ key: "search_gallery", value: res.data.data.galleryList }))
      dispatch(updateRedux({ key: "search_videos", value: res.data.data.videosList }))
      dispatch(updateRedux({ key: "search_reports", value: res.data.data.reportsList }))
      dispatch(updateRedux({ key: "search_awareness", value: res.data.data.awarenessList }))
      dispatch(updateRedux({ key: "search_news", value: res.data.data.newsList }))
      dispatch(updateRedux({ key: "search_research", value: res.data.data.researchList }))
      dispatch(updateRedux({ key: "search_whatwedo", value: res.data.data.whatwedoList }))
      dispatch(updateRedux({ key: "search_loader", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "search_loader", value: true }))
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getAnnouncements = (formData) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-latest-events`,formData).then((res) => {
    if (res.status) {
      dispatch(updateRedux({ key: "announcements_Lists", value: res.data} ))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}
export const getInternationalEvents = () => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}get-international-events`).then((res) => {
    console.log('123',res)
    if (res.data.status) {
      dispatch(updateRedux({ key: "international_Lists", value: res.data.data} ))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getInstaFeed = () => (dispatch) => {
  axiosInstance.get(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&limit=4&access_token=${API_INSTA_TOKEN}`).then((res) => {
    console.log("tested",res.data.data)
    if (res.status) {
      dispatch(updateRedux({ key: "instaFeed_lists", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}