import React, { useEffect, useState } from 'react'
import "./IntroSection.scss"
import introImage from "../../assets/images/intro_high.png"
import { Col, Row } from 'react-bootstrap'
import { API_IMAGE_URL } from '../../constants/configuration'
import i18n from '../../i18n'

const IntroSection = ({data}) => {
      // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);
    return (
        <div className='intro-container' >
            <Row className='align-items-center'>
                <Col md={12} lg={4}>
                    {
                        data?.sub_banner_image ? <img src={API_IMAGE_URL + data?.file_path + data?.sub_banner_image} width={"100%"} className='intro-img' style={{}} /> :
                        <img className='intro-img w-100' src='./assets/img/dummy.png' />
                    }
                    
                </Col>
                <Col md={12} lg={8} className='text-container'>
                    <div className='title mb-3'>
                        {langSwitch ? data?.sub_title : data?.sub_title_ar}
                        
                    </div>
                    <div className='description' dangerouslySetInnerHTML={{__html: langSwitch ? data?.sub_desc : data?.sub_desc_ar}}></div>
                </Col>
            </Row>
        </div>
    )
}

export default IntroSection