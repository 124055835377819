import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../i18n";
import { getResearchDetails } from "../redux/actionCreator";
import { researchDetails } from "../redux/commonReducer";
import { API_IMAGE_URL } from "../constants/configuration";
import { t } from "i18next";

function ResearchDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  // CONSTRUCTOR
  const research_details = useSelector(researchDetails);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    if (id) {
      dispatch(getResearchDetails(id));
    }
  }, [id]);

  let pdffile = langSwitch ? research_details?.document : research_details?.document_ar


  return (
    <div className="report__details">
      <Container>
        <div className="Titlebar_btn">
          <TitleBar
            title={
              langSwitch ? research_details?.title : research_details?.title_ar
            }
            capitalize
            breadcrumbs={[
              { name: t('home'), path: "/" },
              { name: t('research'), path: "/research" },
            ]}
            label="View PDF"
            varient={"warning"}
            noBtn
          />

          {research_details?.document && (
            <a
              href={`${API_IMAGE_URL}${research_details?.file_path}${pdffile}`}
              target="_blank"
            >
              <button className="btn btn-warning text-center" style={{ minWidth: "140px" }}>
                {t('View PDF')}
              </button>
            </a>
          )}
        </div>

        <img
          src={`${API_IMAGE_URL}${research_details?.file_path}${research_details?.banner}`}
          alt=""
        />
<div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? research_details?.content : research_details?.content_ar}}></div>
       
      </Container>
    </div>
  );
}

export default ResearchDetails;
