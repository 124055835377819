import React, { useEffect, useState } from "react";
import SlidesComponent from "../../commonComponents/SlidesComponent/SlidesComponent";
import { Col, Row } from "react-bootstrap";
import event1 from "../../assets/images/event1_high.png";
import "./InternationalEvents.scss";
import rightArrow from "../../assets/images/right_arrow_small.svg";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import { getEvents, getInternationalEvents } from "../../redux/actionCreator";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import moment from "moment";
import { API_IMAGE_URL } from "../../constants/configuration";
import { eventLists, eventListsInternational, featuredEventLists, international_Lists } from "../../redux/commonReducer";
import { t } from "i18next";
import Title from "../../commonComponents/Title/Title";
import DummyImage from "../../components/DummyImage/DummyImage";

const InternationalEvents = () => {
  const dispatch = useDispatch();

  // SELECTOR
  const datas = useSelector(international_Lists);
  const eventItems = datas?.slice(0, 16)

  const localeventItems = useSelector(eventLists);
  const featured = useSelector(featuredEventLists);
  const allLocalEvents = featured?.concat(localeventItems)


  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getInternationalEvents());

  }, []);

  const Tile = (index, ele) => (
    <SwiperSlide>
      <div className="event-grid" key={index}>
        <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`} className="event-image" />
        <div className="mask"></div>
        <h4>{moment.utc(ele?.event_date).locale(lang).format("MMMM DD")}</h4>
        <div className="title">
          <Row>
            <Col sm={9}>
              <p>{langSwitch ? ele?.title : ele?.title_ar}</p>
            </Col>
            {/* <Col><img src={rightArrow}/></Col> */}
          </Row>
        </div>
        <Link to='/Events'><img src={rightArrow} className="arrow" /></Link>
      </div>
    </SwiperSlide>
  );

  return (
    <>
      {
        eventItems?.length > 0 ?
          <SlidesComponent
            title={t("international events")}
            titleurl={'/Events'}
            grid={{
              rows: 4,
              fill: "row",
            }}
            slidesPerGroup={window?.innerWidth < 600 ? 1 : 2}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
            }}
            spaceBetween={15}
            dataArray={eventItems}
            tile={Tile}
          /> : allLocalEvents?.length > 0
            ? <div className="dummy__img">
              <Title title={t("international events")} />
              <DummyImage />
            </div >
            : null
      }

    </>

  );
};

export default InternationalEvents;
