import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import TitleBar from '../components/TitleBar/TitleBar'
import { getPrivacy } from '../redux/actionCreator';
import i18n from '../i18n';
import { privacyDetails } from '../redux/commonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import DummyImage from '../components/DummyImage/DummyImage';

function Privacy() {
  const dispatch = useDispatch();
  // SELECTOR
  const privacy_details = useSelector(privacyDetails);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getPrivacy())
  }, []);

  return (
    <section className='terms__conditions'>
      <Container>
        <TitleBar pt0 noBtn />
        {
          (privacy_details?.title?.trim() == '' && privacy_details?.description?.trim() == '') ?
            <DummyImage size={'60%'} /> :
            <>
              <h2>{langSwitch ? privacy_details?.title : privacy_details?.title_ar}</h2>
              <div className='content' dangerouslySetInnerHTML={{ __html: langSwitch ? privacy_details?.description : privacy_details?.description_ar }}></div>
            </>
        }

      </Container>
    </section>
  )
}

export default Privacy
