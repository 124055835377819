

import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import { getGallery, getGalleryImages } from "../../redux/actionCreator";
import { galleryImages, galleryItems, gallery_count } from "../../redux/commonReducer";
import { API_IMAGE_URL } from "../../constants/configuration";
import moment from "moment";
import { Link } from "react-router-dom";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Fancybox from "../LightBox/fancybox";
import DummyImage from "../DummyImage/DummyImage";
function Gallery() {
  const dispatch = useDispatch();

  const [imgID, setImgID] = useState('')
  const [page, setPage] = useState(0);
  const [index, setIndex] = useState(0)
  const [allImages, setAllImages] = useState([])

  // SELECTOR
  const gallery_lists = useSelector(galleryItems);
  const Remaining_count = useSelector(gallery_count);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", page);
    formData.append("perPage", 8);
    dispatch(getGallery(formData, (res) => {
      setAllImages([...allImages, ...res])
    }));
  }, [page]);

  const handleViewMore = () => {
    setPage(page + 1);
  };

  const handleImage = (ele, i) => {
    setImgID(ele?._id)
    setIndex(i)
  }

  const options = {
    Thumbs: true,
  }
  



  return (
    allImages?.length == 0 ?
    <DummyImage size={'60%'} /> :
    <>

      <div className="row position-relative">

        {allImages?.map((ele, i) => {
          return (
            <div className="col-md-3" key={i} onClick={() => handleImage(ele, i)}>
              <Fancybox options={options}>
                <a data-fancybox={`gallery${i}`} href={`${API_IMAGE_URL}${ele?.file_path}${ele.thumbnail}`}>
                  <div className="library__item" style={{ backgroundImage: `url('${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}')` }}>
                    <div className="overlay"></div>
                    <div className="library__contents">
                      <h3>{moment.utc(ele?.created_date).locale(lang).format("LL")}</h3>
                      <h6>{langSwitch ? ele?.title : ele?.title_ar}</h6>
                    </div>

                  </div>
                </a>
              </Fancybox>
            </div>

          );
        })}
        <>
          <div className="d-none">
            {allImages?.[index]?.gallery?.map((ele, i) => {
              return (
                <a data-fancybox={`gallery${index}`} href={`${API_IMAGE_URL}/library/${ele}`}>
                  <img src={`${API_IMAGE_URL + '/library/' + ele}`} width="100" />
                </a>
              );
            })}
          </div>
        </>
        <div className="col-md-12">
          {!Remaining_count == 0 && (
            <div className="text-center mb-5 mt-4">
              <RoundedButton
                varient="secondary"
                style={{ minWidth: "190px" }}
                onClick={() => handleViewMore()}
                title="View More"
              />
            </div>
          )}
        </div>

      </div>


    </>
  );
}

export default Gallery;
