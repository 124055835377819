import React, { useEffect } from 'react'
import Title from '../../commonComponents/Title/Title'
import { Col, Container, Row } from 'react-bootstrap'
import "./AboutSection.scss"
import RoundedButton from '../../commonComponents/RoundedButton/RoundedButton'
import { Link, useNavigate } from 'react-router-dom'
import { getAboutus } from '../../redux/actionCreator'
import { about_details, logo_ar, logo_en } from '../../redux/commonReducer'
import { useDispatch, useSelector } from 'react-redux'
import logo from "../../assets/images/logo.svg";
import icon from "../../assets/images/right_arrow_yellow.svg";
import { t } from 'i18next'

const AboutSection = ({ langSwitch }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // SELECTOR
    const logo_arabic = useSelector(logo_ar)
    const logo_english = useSelector(logo_en)
    const aboutData = useSelector(about_details)

    useEffect(() => {
        dispatch(getAboutus())
    }, [])

    return (
        <div id='About_Us' data-aos="fade-up" data-aos-duration="2000">
           
            {/* <Container> */}
            <Row className='align-items-center home__about'>
                <Col md={12} lg={4}>
                    <div className="logo__about">
                        {
                            !logo_english == '' && !logo_arabic == '' ? <img
                                alt=""
                                src={langSwitch ? logo_english : logo_arabic}
                                className="d-inline-block align-top logo-image"
                            /> : <img
                                alt=""
                                src={logo}
                                className="d-inline-block align-top logo-image"
                            />
                        }
                    </div>
                </Col>
                <Col md={12} lg={8}>
                <Title title={t("about us")} titleurl={'/about'} />
                    <div className='description mt-4'>
                        <p dangerouslySetInnerHTML={{ __html: langSwitch ? aboutData?.description : aboutData?.description_ar }}></p>
                    </div>
                    <Link to='/about' className="know-more">{t('know more')} <img src={icon} alt="" /></Link>
                </Col>
                
            </Row>
            
            {/* </Container> */}
        </div>
    )
}

export default AboutSection