import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Pagination,
  Navigation,
  Grid,
  EffectFade,
} from "swiper/modules";
import "./SwiperComponent.scss";
import i18n from "../../i18n";

const SwiperComponent = ({
  fade,
  className,
  pagination,
  data,
  slidesPerView,
  slidesPerGroup,
  nextRef,
  prevRef,
  setInit,
  breakpoints = {},
  autoplay = false,
  spaceBetween = 30,
  grid = {},
}) => {

  const defaultLang = localStorage.getItem('localLang')
  const rtlSwitch = i18n?.dir()

  return (
    <>
      <Swiper
      
        spaceBetween={spaceBetween}
        autoplay={autoplay}
       dir={rtlSwitch}
        pagination={
          pagination && {
            clickable: true,
          }
        }
        effect={fade}
        modules={[Autoplay, Pagination, Navigation, Grid, EffectFade]}
        className={className ?? "mySwiper"}
        slidesPerView
        slidesPerGroup={slidesPerGroup ?? 2}
        navigation={{
          prevEl: prevRef && prevRef.current,
          nextEl: nextRef && nextRef.current,
        }}
        /*update state on swiper initialization*/
        onInit={() => setInit && setInit(true)}
        breakpoints={breakpoints}
        grid={grid}
        onEnded={() => alert("test")}
        key={rtlSwitch}
      >
      {data}
      </Swiper>
    </>
  );
};

export default SwiperComponent;
