import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import TitleBar from '../components/TitleBar/TitleBar'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { eventDetails } from '../redux/commonReducer'
import { getEventDetails } from '../redux/actionCreator'
import { API_IMAGE_URL } from '../constants/configuration'
import i18n from '../i18n'
import moment from 'moment'
import { t } from 'i18next'

function InternationalDetails() {
  const { id } = useParams()
  const dispatch = useDispatch()

  // CONSTRUCTOR
  const event_details = useSelector(eventDetails)

  // LANGUAGE
  const [lang, setLang] = useState('')
  const langSwitch = i18n?.language === 'en'
  useEffect(() => {
    i18n.on('languageChanged', language => {
      setLang(language)
    })
  }, [lang, i18n])

  useEffect(() => {
    if (id) {
      dispatch(getEventDetails(id))
    }
  }, [id])

  return (
    <section className="local__events">
      <Container>
        <TitleBar
          title={langSwitch ? event_details?.title : event_details?.title_ar}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('international events'), path: "/Events" },
          ]}
          capitalize
          noBtn
        />
        <div className="row">
          <div className="col-md-12">
            <div className="localEvent__grid details">
              {
                event_details?.image ?  <img src={`${API_IMAGE_URL}${event_details?.file_path}${event_details?.image}` } className="main w-100" alt="" /> : 
                <div className="details_loader"></div>
              }
             
              <div className="localGrid">
                <div className="icon">
                  <img src="/assets/img/icons/calendar.svg" alt="" />
                  <p>{langSwitch ? moment(event_details?.event_date).format("LL") : moment(event_details?.event_date).format("LL")}</p>
                </div>
                <div className="icon">
                  <img src="/assets/img/icons/location.svg" alt="" />
                  <p>{langSwitch ? event_details?.location : event_details?.location_ar}</p>
                </div>
                <div className="icon">
                  <img src="/assets/img/icons/clock.svg" alt="" />
                  <p>{moment.utc(event_details?.event_date).format('LT')}</p>
                </div>
              </div>
              <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? event_details?.content : event_details?.content_ar}}></div>
            </div>
          </div>

        </div>
      </Container>
    </section>
  )
}


export default InternationalDetails
