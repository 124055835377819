import React from "react";
import "./RoundedButton.scss";
import icon from "../../assets/images/right_arrow_yellow.svg";
import { Button } from "react-bootstrap";

const RoundedButton = ({ title, style, varient, noIcon, onClick }) => {
  return (
    <Button
      variant={varient}
      style={style }
      onClick={() => onClick && onClick()}
      className={noIcon ? 'text-center' :''}
      
    >
      {title}
      {noIcon ?? <img src={icon} className="btn-icon" alt="" />}
    </Button>
  );
};

export default RoundedButton;
